import React from "react";
import { FormControl } from "react-bootstrap";

import { CoiFile } from "../models";

import { Button } from "@procore/core-react";
import { showErrorMessage } from "../utils";
import { Paperclip } from "@procore/core-icons/dist";

const Attachment = (props: {
	index: number;
	onFileAdded: (files: FileList) => void;
	onFileRemoved: (file: CoiFile) => void;
	files: Array<CoiFile> | null;
	isFilesMandatory: boolean;
    actionDisabled: boolean;
}) => {
	const inputRef = React.useRef<any>(null);

    const totalFileSizeErrorMessage = React.useMemo(() => {
        if (props.files && props.files?.length) {
            let coiFilesTotalSize: number = 0;

            for (let index = 0; index < props.files.length; index++) {
                const coiFile = props.files[index];
                coiFilesTotalSize += coiFile.file.size;
            }
            const fileSizeinMB = Math.round(coiFilesTotalSize / 1024);
            // filesize greater than 12MB
            if (fileSizeinMB > 10240 && props.files.length > 1) {              
                return "Maximum size of all files should be less than 10MB.";
            } else {
                return null;
            }
        }
    }, [props.files]);

    return (
        <div
            className="row no-gutters"

        >
            <FormControl
                hidden
                ref={inputRef}
                size="lg"
                type="file"
                accept="image/jpeg,application/pdf"
                placeholder="Attach Files"
                key={`file-attach-${props.index}`}
                onChange={(e: any) => {
                    const file = e?.target?.files[0];
                    const isDuplicate = props.files?.find(x => x.file.name === file?.name);
                    if (isDuplicate) {
                        showErrorMessage('Uploaded files cannot have the same name.')
                    }
                    if (!isDuplicate) {
                        props.onFileAdded(e?.target?.files);
                        if (inputRef && inputRef.current?.value) {
                            inputRef.current.value = "";
                            inputRef.current.files = null;
                        }
                    }
                }}
            />
            <div className="col-md-3">
                <Button
                    size="sm"
                    variant="secondary"
                    disabled={props.actionDisabled}
                    onClick={() => {
                        if (inputRef && inputRef.current) {
                            inputRef.current.value = "";
                            inputRef.current.files = null;
                            inputRef.current?.click();
                        }
                    }}
                >
                    <Paperclip size="sm" />
                    Attach
                </Button>
            </div>
            <div 
                className={`col-md-9 ${totalFileSizeErrorMessage && totalFileSizeErrorMessage.length
                    ? "border rounded border-danger pl-1"
                    : ""
                    }`}
                style={{ maxHeight: "60px", overflowY: "auto" }}>
                {props?.files && props?.files.length ? (
                    <div style={{ width: 285 }}>
                        {props?.files.map((file: CoiFile, idx: number) => {
                            let fileSplit = file.file.name.split('.');
                            let filename = file.file.name.replace('.' + file.file.name.split('.')[fileSplit.length - 1],'');
                            let fileext = file.file.name.split('.')[fileSplit.length-1];
                            return (
                                <div key={`coi-files=${idx}`}>
                                    <div
                                        className={`row no-gutters mb-1 file-list-item ${file.errors && file.errors.length
                                                ? "border rounded border-danger pl-1"
                                                : ""
                                            }`}
                                    >
                                        <section className="containerpdf" title={file.file.name}>
                                            <div className="onepdf">

                                                <div className="col-md-12 float-right text-right px-0 truncate-text">
                                                    <span className="" >{filename}</span>
                                                </div></div>
                                            <div className="twopdf"> <span>.{fileext}</span></div>
                                        </section>

                                        <div className="col-md-2">
                                            <Button
                                                style={{ height: 16}}
                                                size="sm"
                                                variant="secondary"
                                                disabled={props.actionDisabled}
                                                onClick={() => {
                                                    props.onFileRemoved(file);
                                                }}
                                            >
                                                ✕
                                            </Button>
                                        </div>
                                    </div>
                                    {file.errors && file.errors.length ? (
                                        <div className="row no-gutters mb-1">
                                            {file.errors.map((error: string, idx2: number) => {
                                                return (
                                                    <span
                                                        key={`attachment-error-${idx2}`}
                                                        className="text-danger file-list-item"
                                                    >{`• ${error}`}</span>
                                                );
                                            })}
                                        </div>
                                    ) : null}
                                </div>
                            );
                        })}

                        {totalFileSizeErrorMessage ? (
                            <div className="row no-gutters mb-1">
                                <span className="text-danger file-list-item">{`• ${totalFileSizeErrorMessage}`}</span>
                            </div>
                        ) : null}
                    </div>
                ) : props.isFilesMandatory && (!props.files || !props.files.length) ? (
                    <div style={{ width: 149 }}>
                        <span className="text-danger file-list-item">{`Either attach a COI or select the Request COI box.`}</span>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default Attachment;