import React, { useCallback, useRef, useState } from "react";
import { columnsglobal } from "../columns";
import { Typeahead, Button, Tooltip } from "@procore/core-react";
import { PersonPlus } from '@procore/core-icons/dist'
import { Plus } from '@procore/core-icons/dist'
//import { useLoad } from "../hooks"
import NoResults from '../../../shared/NoResults'
import DataTable from "../../../components/DataTable";
import debounce from "lodash/debounce";
import { Modal } from "react-bootstrap";
import CreateRequest from "../create-request";
import DocumentIcon from "../../../shared/images/DocumentIcon.png";
import Open_link from "../../../shared/images/Open_link.png";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import FileUploader from 'devextreme-react/file-uploader';
import { receiptMimes, FileUpload, CoiFile } from "../../../models";
import fileuploadLogo from "../../../shared/images/upload-file.png";
import fileViewLogo from "../../../shared/images/upload-viewdocs.png";
import plusLogo from "../../../shared/images/plus-image.png";
import { showErrorMessage, showSuccessMessage } from "../../../../src/utils";






function AllGloablTableRequest(props:{
       loadingGlobalJonesVendors:boolean;
       Coifileload:boolean;
       jonesGlobalVendor: any;
       onUploadCoiFiles:any;
       onLoadCoiFiles:any;
       onLoadGlobalJonesVendors:any
       jonespermission:boolean

}) {
    let {
       
        


    } = props;



    const [searchContent, setSearchContent] = useState<string>("");
    const [isShowModal, setIsShowModal] = useState<boolean>(false);
    const [isShowFileModal, setisShowFileModal] = useState<boolean>(false);
    const [isDropZoneActive, setIsDropZoneActive] = React.useState<boolean>(false);
    const [receipts, setReceipts] = React.useState<FileUpload[]>([]);

    const [selectedFiles, setSelectedFiles] = React.useState<any>([]);
    const [selectedrow, setselectedrow] = React.useState<string>("");
    const [fileSizeError, setFileSizeError] = React.useState<any>([]);
    const [selectedstatus, setselectedstatus] = React.useState<string>("");

    const [selectedrowid, setselectedrowid] = React.useState(0);





   // const { loadingGlobalJonesVendors,  jonesGlobalVendor, onLoadGlobalJonesVendors, downloading, onDownLoadFile, errorMessage, onLoadCoiFiles, onUploadCoiFiles, Coifileload } = useLoad();
    const dataGrid = React.useRef<any>(null);


    const onDropZoneEnter = React.useCallback((e: any) => {
        if (e.dropZoneElement.id === "dropzone-external") {
            setIsDropZoneActive(true);
        }
    }, []);
    const onDropZoneLeave = React.useCallback((e: any) => {
        if (e.dropZoneElement.id === "dropzone-external") {
            setIsDropZoneActive(false);
        }
    }, []);




    const onDeletefile = useCallback(
        (name: string) => {
            const files = receipts.filter((x) => x.fileName !== name);
            const updatedFiles = selectedFiles.filter(
                (x: any) => x?.file?.name?.toLowerCase().trim() !== name?.toLowerCase().trim()
            );

            setReceipts(files);
            setSelectedFiles(updatedFiles);

        },
        [receipts, selectedFiles]
    );
    var conrint = "";

    const handleAddFile = React.useCallback(
        (invoiceData: any) => {
            let errors: string[] = [];
            let mgs = "";
            let coiFilesTotalSize: number = 0;
            const newFile = invoiceData.slice(-1);

            const isFileHasSameName = selectedFiles.find((x: any) => x.file.name === newFile[0]?.name);


            if (isFileHasSameName) {
                showErrorMessage('Uploaded files cannot have the same name.')
                return;


            }
            const validFileExtensions = ["jpg", "jpeg", "pdf"];
            const extension = newFile[0].name.split(".")?.pop()?.toLowerCase();
            const isExtensionAllowed = validFileExtensions.find(
                (x) => x === extension
            );
            if (!isExtensionAllowed) {

                mgs = "Invalid file format. Accepted formats are PDF and JPEG.";
                errors.push(mgs);

            }


            const fileSize = Math.round(newFile[0].size / 1024);
            // filesize greater than 10MB
            if (fileSize > 10240) {

                mgs = "Maximum file size is 10MB.";
                errors.push(mgs);

            }


            for (let index = 0; index < selectedFiles.length; index++) {
                const coiFile = selectedFiles[index];
                coiFilesTotalSize += coiFile.file.size;
            }
            coiFilesTotalSize += newFile[0].size;
            const fileSizeinMB = Math.round(coiFilesTotalSize / 1024);
            // filesize greater than 12MB
            if (fileSizeinMB > 10240 && selectedFiles[0]) {

                setFileSizeError("Maximum size of all files should be less than 10MB.");

                /*   errors.push(mgs); */
            }
            const formatNewFile = newFile.map((file: File) => {

                return {
                    file: file,
                    error: mgs,
                };

            });           
            setSelectedFiles([...selectedFiles, ...formatNewFile]);
        },
        [selectedFiles]
    );

    const setfileData = (arr: any) => {
        setReceipts(arr);
    };
    const popover = (
        <Popover id="popover-basic"   >
            <div className="btn-group" >
                <div className="totip-global">
                    <div className="py-2">
                        <div>
                            <button className="btn btn-light btn-block view-upload-btn-global" style={{ fontSize: "inherit" }} disabled={selectedstatus === 'Pending'} onClick={async (event) => {
                                document.body.click()
                                const fileurl = await props.onLoadCoiFiles(selectedrow, selectedrowid);
                                if (fileurl != "" && selectedrow != null) { window.open(fileurl) }

                            }}> <img src={fileViewLogo} alt={fileViewLogo} width="18" /> {" "} {" "}
                                {" "}<span style={{ paddingLeft: "inherit" }}>View Docs </span>
                            </button>
                        </div>
                    </div>


                    <div className="py-2">

                        <button disabled className="btn btn-light btn-block view-upload-btn-global" style={{ fontSize: "inherit" }}
                            onClick={(event) => {

                                document.body.click()
                                setisShowFileModal(true);
                                setSelectedFiles([]);

                            }}
                        ><img src={plusLogo} alt={plusLogo} width="18" /> {"  "} {" "}
                            {" "}
                            <span className="global-btn">Upload New</span>
                            <div><small className="text-muted" style={{ paddingLeft: "inherit" }}>(Managed in Jones global app)</small></div>
                        </button>{" "}
                    </div>
                </div>
            </div>
        </Popover>
    );
    // React.useEffect(() => {
    //     onLoadGlobalJonesVendors();

    // }, [onLoadGlobalJonesVendors])


    columnsglobal.forEach((e) => {

        if (e.caption === 'Insurance Documents') {

            e.cellRender = (cellData: any, index: number) => {
                var selectedrecord = "";
                var selectstatus = "";
                var selectedid = 0;

                return (

                    <div>
                        {(cellData.data.complianceStatus != 'No COI Required') && (cellData.data.complianceStatus != 'Deleted on Jones') && (cellData.data.complianceStatus != 'Archived') ? (
                            <OverlayTrigger rootClose={true} trigger="click" placement="bottom" overlay={popover}>


                                <button className="border-0 bg-transparent" onClick={(event) => {
                                    selectedrecord = cellData.data.foreignid2;
                                    selectstatus = cellData.data.complianceStatus;
                                    selectedid = cellData.data.id;
                                    selectstatus = cellData.data.complianceStatus;
                                    setselectedstatus(selectstatus);
                                    setselectedrow(selectedrecord);
                                    setselectedrowid(selectedid);

                                }}
                                >
                                    <img src={DocumentIcon} alt={DocumentIcon} width="20" />
                                </button>

                            </OverlayTrigger>) : null}

                    </div>
                );
            };
        }
    }); 
   
    columnsglobal.forEach((e) => {
        if (e.caption === "Open in Jones") {
            e.visible = props.jonespermission
           
          e.cellRender = (cellData: any, index: number) => {             
      
            const handleOpenInJones = () => {
             
                const foreignid = cellData.data.foreignid2 ;
                const DeepUrl=cellData.data.deepUrl;         
                const updatedUrl = `${DeepUrl}=${foreignid}&reportTab=report`;         
                window.open(updatedUrl, '_blank');
      
              
            };
      
            return (
              <div>
                {cellData.data.complianceStatus != "COI Request Error" ? (
                <button
                  className="border-0 bg-transparent"
                  onClick={handleOpenInJones}
                >
                  <img src={Open_link} alt={Open_link} width="20" />
                </button>
                ):<button className="border-0 bg-transparent">
                <img src={Open_link} alt={Open_link} width="20" className="blur-image" />
              </button>}
              </div>          
            );
          };
        }
      });
    
       
        return (
            <div className="mt-3 pt-2">
                <div className="row">
                    <div className="col-md-12">
                        {props.loadingGlobalJonesVendors || props.Coifileload ? (
                            <div className="container container-wrapper p-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="alert alert-light text-center">
                                            <img src={"/images/spinner.gif"} alt="Loading" />
                                        </div>

                                    </div>
                                </div>
                            </div>


                        ) : props.jonesGlobalVendor && props.jonesGlobalVendor.length ? (
                            <div className="separator pt-3">
                                <div className="table-title">
                                    <span>Global Requests</span>
                                    <p><small>Managed through the “Jones app - Global compliance solution” </small> </p>
                                </div>
                                <DataTable
                                    id="gridContainer1"
                                    dataSource={props.jonesGlobalVendor}
                                    keyExpr="id"
                                    columns={columnsglobal}
                                    loading={props.loadingGlobalJonesVendors}
                                    pageSize={10}
                                /></div>
                        ) :
                            <div className="separator pt-3">
                                <div className="table-title">
                                    <span>Global Requests</span>
                                    <p><small>Managed through the “Jones app - Global compliance solution” </small> </p>
                                </div>
                                <DataTable
                                    id="gridContainer1"

                                    keyExpr="id"
                                    columns={columnsglobal}
                                    loading={props.loadingGlobalJonesVendors}
                                    

                                /></div>
                        }
                    </div>
                </div>
                {isShowModal &&
                    (<Modal
                        show={isShowModal}
                        keyboard={false}
                        animation={false}
                        dialogClassName="create-request"
                        backdrop="static"
                        centered
                        onHide={() => { }}
                    >
                        <Modal.Body className="px-0">

                             <CreateRequest
                                showCOIModal={true}
                                onSaved={props.onLoadGlobalJonesVendors}
                                setIsShowModal={setIsShowModal}
                                IscompanyId={""}
                                onClose={() => { setIsShowModal(false) }} /> 
                        </Modal.Body>
                    </Modal>)}


                {isShowFileModal &&
                    (<Modal
                        show={isShowFileModal}
                        keyboard={false}
                        animation={false}
                        dialogClassName="create-fileupload"
                        backdrop="static"
                        centered
                        onHide={() => { }}
                    >
                        <Modal.Header style={{ borderBottom: "0 none" }}>
                            <div className="fileuploadhead">Upload Insurance Document(s)</div>
                            <button className="fileupload-close-btn" onClick={(event) => { setisShowFileModal(false) }} >X
                            </button>
                        </Modal.Header>
                        <div className={"modal-body minheight " + (selectedFiles.length < 5 ? "" : " list-view")}>

                            <div
                                className={`col-md-12 ${fileSizeError && fileSizeError.length
                                    ? "border rounded border-danger pl-1"
                                    : ""
                                    }`}>
                                <div className="row">

                                    {selectedFiles.map(
                                        (filename: any, indexKey: number) => {
                                            let fileSplit = filename.file.name.split('.');
                                            let filename1 = filename.file.name.replace('.' + filename.file.name.split('.')[fileSplit.length - 1], '');
                                            let fileext = filename.file.name.split('.')[fileSplit.length - 1];

                                            return (

                                                <div className="col-auto mb-1">

                                                    <div className={`row no-gutters fileupload-list d-inline-block ${filename.error
                                                        ? "border rounded border-danger pl-1"
                                                        : ""
                                                        }`}
                                                    >
                                                        {filename?.file.name.length < 55 ?
                                                            <div><span className="fileupload-list-name" >
                                                                {filename?.file.name} </span>


                                                                <Button className="fileupload-close-btn"
                                                                    style={{ height: 16 }}
                                                                    variant="secondary"
                                                                    onClick={(e) => {
                                                                        onDeletefile(filename?.file.name);
                                                                        setFileSizeError("")
                                                                    }}
                                                                >
                                                                    X
                                                                </Button>


                                                            </div>
                                                            :
                                                            <> <section id={filename?.file.name}  >
                                                                    <div className="onepdf">
                                                                        <div className="col-md-12 float-right text-right px-0 truncate-text fileupload-list-name" style={{ width: "345px" }}>
                                                                            <span className="" >{filename1} </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="twopdf fileupload-list-name"> <span>.{fileext}</span>
                                                                        <Button className="fileupload-close-btn"
                                                                            style={{ height: 16 }}
                                                                            size="sm"
                                                                            variant="secondary"
                                                                            onClick={() => {
                                                                                onDeletefile(filename?.file.name);
                                                                                setFileSizeError("")
                                                                            }}
                                                                        >
                                                                            ✕
                                                                        </Button>
                                                                    </div>
                                                                </section>
                                                            </>}
                                                    </div>
                                                    {filename.error && (
                                                        <p
                                                            className="text-danger1 file-list-item"
                                                        >
                                                            {filename.error}
                                                            <p className="d-none">

                                                                {conrint = "Error uploading files. Please remove before continuing."}
                                                            </p>


                                                        </p>
                                                    )}


                                                </div>
                                            );
                                        }
                                    )}

                                </div>

                            </div>
                            {fileSizeError ? (
                                <div className="row no-gutters mb-1">
                                    <span className="text-danger file-list-item">{fileSizeError}</span>
                                </div>
                            ) : null}
                        </div>
                        <Modal.Body className="px-0">
                            <div className="grid flex">
                                <div className="flex bg-gray-200" >
                                    <div className="flex flex-1 bg-white m-1 items-center justify-center ">

                                        <div
                                            className={`flex items-center flex-box ${isDropZoneActive
                                                ? 'dx-theme-accent-as-border-color dropzone-active'
                                                : 'dx-theme-border-color'
                                                }`}
                                        >  <div id="dropzone-external">
                                                <div id="dropzone-text" className="flex-box h-max">
                                                    <span>
                                                        <img style={{ marginLeft: "27px" }} src={fileuploadLogo} alt={fileuploadLogo} width="40" />
                                                        <br></br>
                                                        <div style={{ marginTop: "5px" }}>
                                                            <span style={{ color: "#337ab7", fontSize: "16px" }}>Click  </span> <span style={{ fontSize: "16px" }}>
                                                                to Upload</span>
                                                        </div>

                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <FileUploader
                                    id="file-uploader"
                                    dialogTrigger="#dropzone-external"
                                    dropZone="#dropzone-external"
                                    accept={receiptMimes}
                                    multiple={false}
                                    visible={false}

                                    showFileList={true}
                                    onDropZoneEnter={onDropZoneEnter}
                                    onDropZoneLeave={onDropZoneLeave}
                                    onValueChange={(e) => {
                                        if (e && e.length) {
                                            handleAddFile(e);
                                        }
                                    }}
                                />
                            </div>
                        </Modal.Body>
                        <Modal.Footer style={{ borderTop: "0 none" }}>
                            <div>                               
                                {conrint != "" ? (<span className="error-show"> {conrint} </span>) : ""}
                            </div>
                            <div style={{ paddingRight: "inherit" }}>
                                <button className="add-cancel-btn"

                                    onClick={() => {

                                        setFileSizeError("");
                                        setisShowFileModal(false);
                                        setSelectedFiles([]);
                                        showSuccessMessage('No docs uploaded')
                                        return;
                                    }}
                                > Cancel

                                </button>
                            </div>
                            <div style={{ paddingRight: "inherit" }}>

                                {selectedFiles.error}
                                <Button disabled={selectedFiles.length == 0 || conrint != "" || fileSizeError != ""} className="add-fileupload-btn"

                                    onClick={() => {
                                        props.onUploadCoiFiles(selectedFiles, selectedrow, selectedrowid);
                                        setisShowFileModal(false);
                                        return;
                                    }}
                                > Done
                                </Button>
                            </div>



                        </Modal.Footer>
                    </Modal>)}

            </div>
        )
    

}

export default AllGloablTableRequest;